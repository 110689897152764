'use client';
import { useEffect } from 'react';
export default function CustomerAccounts() {
  useEffect(() => {
    const scriptTag = window.document.createElement('script');
    scriptTag.src = `${process.env.NEXT_PUBLIC_CUSTOMER_ACCOUNTS_URL}/static/js/main.js`;
    scriptTag.async = true;
    window.document.head.appendChild(scriptTag);
    const cssTag = window.document.createElement('link');
    cssTag.href = `${process.env.NEXT_PUBLIC_CUSTOMER_ACCOUNTS_URL}/static/css/main.css`;
    cssTag.rel = 'stylesheet';
    window.document.head.appendChild(cssTag);
    return () => {
      window.document.head.removeChild(scriptTag);
      window.document.head.removeChild(cssTag);
    };
  }, []);
  return (
    <>
      <div id="cust-account"></div>
    </>
  );
}
